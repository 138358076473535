import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import allegroImg from '@/assets/images/brands/allegro.jpg';
import ceneoImg from '@/assets/images/brands/ceneo.jpg';
import ceneoCertificateImg from '@/assets/images/certificates/ceneo.jpg';
import { Slider } from '@/components/Slider';
import { Grid } from '@/components/_atoms/Grid';
import { Typography } from '@/components/_atoms/Typography';
import { ProductRating } from '@/components/_molecules/ProductRating';
import { ALLEGRO_REVIEWS_ULR, CENEO_BADGE_URL, CENEO_REVIEWS_ULR } from '@/constants/url.constants';
import { MAX_PRODUCT_RATING, useProductRate } from '@/logic/product/useProductRate';
import { MainPageReviewSection } from '@/network/GeneratedApi';
import { MessagesKey } from '@/types/translations.types';

import { ReviewItem } from './components/ReviewItem';

interface Props {
  data: MainPageReviewSection;
}

export const ReviewsSection = (props: Props) => {
  const { data } = props;
  const { rating_customer, rating_delivery, rating_description, rating, reviews } = data;
  const { getRating } = useProductRate();

  const ratingSummary: { id: number; value: string; labelId: MessagesKey }[] = [
    {
      id: 1,
      value: rating_description,
      labelId: 'homeScreen.reviews.ratingSummary.description',
    },
    {
      id: 2,
      value: rating_customer,
      labelId: 'homeScreen.reviews.ratingSummary.customer',
    },
    {
      id: 3,
      value: rating_delivery,
      labelId: 'homeScreen.reviews.ratingSummary.delivery',
    },
  ];

  return (
    <>
      <Grid className="mb-8 laptop:mb-14">
        <Grid.Item mobile={12} laptop={6}>
          <Typography
            as="p"
            bold
            id="homeScreen.reviews.header"
            className="mb-4 text-header-l laptop:mb-10 laptop:text-header-xl"
          />
          <Typography id="homeScreen.reviews.text" color="grey-standard" className="text-body-l laptop:text-body-xl" />
        </Grid.Item>
        <Grid.Item mobile={12} laptop={6}>
          <div className="flex flex-col items-center justify-center">
            <p className="mb-4 mt-8 text-[130px] font-bold leading-[152px] laptop:mt-4">
              {
                getRating(rating, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).ratingAsStr
              }
            </p>
            <p className="text-body-xl font-bold">
              <FormattedMessage id="homeScreen.reviews.ratingInfo" />
            </p>
          </div>
        </Grid.Item>
      </Grid>
      <div className="mb-4 flex flex-col gap-x-6 laptop:mb-14 laptop:flex-row-reverse laptop:items-center">
        <div className="grid flex-1 grid-cols-1 divide-y divide-grey-300 laptop:grid-cols-3 laptop:gap-6 laptop:divide-y-0">
          {ratingSummary.map((item) => (
            <div
              className="flex h-16 items-center justify-between laptop:h-auto laptop:flex-col laptop:items-center laptop:justify-center"
              key={item.id}
            >
              <Typography variant="body_m" id={item.labelId} className="laptop:mb-2" />
              <div className="flex items-baseline">
                <Typography
                  variant="body_m"
                  bold
                  as="span"
                  id="homeScreen.reviews.ratingSummary.rating"
                  values={{
                    value: (
                      <Typography variant="header_l" bold as="span">
                        {
                          getRating(item.value, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).ratingAsStr
                        }
                      </Typography>
                    ),
                    max: MAX_PRODUCT_RATING,
                  }}
                  className="mr-3"
                />
                <ProductRating value={item.value} showText={false} />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-3 flex-1">
          <Typography variant="body_l" bold id="homeScreen.reviews.info" />
          <div className="grid grid-cols-3 items-center laptop:flex laptop:gap-12">
            <Link href={ALLEGRO_REVIEWS_ULR} className="flex justify-center" target="_blank">
              <Image src={allegroImg} unoptimized alt="Allegro" />
            </Link>
            <Link href={CENEO_REVIEWS_ULR} className="flex justify-center" target="_blank">
              <Image src={ceneoImg} unoptimized alt="Ceneo opinie" />
            </Link>
            <Link href={CENEO_BADGE_URL} className="flex justify-center" target="_blank">
              <Image src={ceneoCertificateImg} unoptimized alt="Ceneo badge" />
            </Link>
          </div>
        </div>
      </div>
      <Slider
        pagination
        navigation
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
        }}
      >
        {reviews.map((item, index) => (
          <Slider.Slide key={index}>
            <ReviewItem {...item} />
          </Slider.Slide>
        ))}
      </Slider>
      <Typography variant="body_s" color="grey-standard" id="homeScreen.reviews.ceneoReviews" className="text-center" />
    </>
  );
};
