import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';

interface ItemProps {
  index: number;
  headerId: MessagesKey;
  textId: MessagesKey;
}

export const TyresSectionWhenItem = (props: ItemProps) => {
  const { index, headerId, textId } = props;
  return (
    <div>
      <div className="mb-4 inline-flex size-10 items-center justify-center rounded-full bg-grey-1000">
        <Typography variant="body_m" color="white">
          {index}
        </Typography>
      </div>
      <Typography id={headerId} bold className="mb-2 text-body-l laptop:text-body-xl" />
      <Typography className="text-body-m laptop:text-body-l" id={textId} />
    </div>
  );
};

export const TyresSectionWhen = () => {
  return (
    <div className="w-full rounded-lg border border-grey-300 p-6 laptop:p-8">
      <ul className="flex flex-col divide-y divide-grey-400 laptop:flex-row laptop:divide-x laptop:divide-y-0">
        <li className="flex-1 pb-6 laptop:pb-0 laptop:pr-6">
          <TyresSectionWhenItem
            index={1}
            textId="homeScreen.tyres.when.point1.text"
            headerId="homeScreen.tyres.when.point1.header"
          />
        </li>
        <li className="flex-1 py-6 laptop:px-6 laptop:py-0">
          <TyresSectionWhenItem
            index={2}
            textId="homeScreen.tyres.when.point2.text"
            headerId="homeScreen.tyres.when.point2.header"
          />
        </li>
        <li className="flex-1 pt-6 laptop:pl-6 laptop:pt-0">
          <TyresSectionWhenItem
            index={3}
            textId="homeScreen.tyres.when.point3.text"
            headerId="homeScreen.tyres.when.point3.header"
          />
        </li>
      </ul>
    </div>
  );
};
