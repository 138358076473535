import React from 'react';

import { Faq } from '@/components/Faq';
import { Typography } from '@/components/_atoms/Typography';
import { useTranslations } from '@/hooks/useTranslations';

const items = [1, 2, 3, 4, 5, 6] as const;

export const FaqSection = () => {
  const { t } = useTranslations();
  const faq = items.map((item) => ({
    id: item,
    question: t(`homeScreen.faq.question${item}`),
    answer: t(`homeScreen.faq.answer${item}`),
  }));
  return (
    <div className="space-y-6">
      <Typography as="h3" bold id="homeScreen.faq.header" className={'text-header-l laptop:text-header-xl'} />
      <Typography as="p" id="homeScreen.faq.description" className={'text-body-m laptop:text-body-l'} />
      <Faq data={faq} questionTag="h4" />
    </div>
  );
};
