import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TyreImage } from '@/components/TyreImage';
import { Typography } from '@/components/_atoms/Typography';
import { LinkButton } from '@/components/_molecules/Button/LinkButton';
import { ProductRating } from '@/components/_molecules/ProductRating';
import { ProductSeasonIcon } from '@/components/_molecules/ProductSeasonIcon';
import { usePrice } from '@/logic/price';
import { MainPageTyreModel } from '@/network/GeneratedApi';
import { getTyreModelUrl } from '@/utils/router.utils';

export const RecommendedItem = (props: MainPageTyreModel) => {
  const { producer, tread, rating, cheapest_price, image, path, season } = props;
  const formatPrice = usePrice();
  return (
    <div className="flex h-[428px] flex-col justify-between rounded-lg bg-grey-100 p-6">
      <div>
        <Link href={getTyreModelUrl(path)}>
          <Typography
            variant="body_l"
            id="homeScreen.recommended.item.title"
            values={{
              producer,
              tread,
            }}
          />
        </Link>
        {rating && (
          <div className="mt-2">
            <ProductRating value={rating} />
          </div>
        )}
        <p className="mb-12 mt-6 text-body-xxl font-bold">
          <FormattedMessage
            id="homeScreen.recommended.item.price"
            values={{ price: formatPrice(cheapest_price, { maximumFractionDigits: 0 }) }}
          />
        </p>
      </div>
      <div>
        <div className="relative flex h-[150px] max-h-[150px] justify-center overflow-hidden text-center">
          <div className="absolute left-0 top-0">
            <ProductSeasonIcon season={season} />
          </div>
          <div className="absolute top-0 mix-blend-multiply contrast-100">
            <TyreImage
              src={image}
              size="l"
              style={{
                width: 190,
                height: 270,
              }}
              alt={`${producer} ${tread}`}
            />
          </div>
        </div>
        <LinkButton
          href={getTyreModelUrl(path)}
          textId="homeScreen.recommended.item.button"
          variant="primary"
          className="w-full"
        />
      </div>
    </div>
  );
};
